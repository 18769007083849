<template>
  <ion-page id="index">
    <ion-header>
      <ion-toolbar color="primary">
        <div slot="start" class="header-icon">
          <img :src="$asset('/images/e-gro_green.svg')" />
        </div>
        <ion-badge v-if="validUntil" color="warning" slot="end">
          {{ `Link expires: ${validUntil}` }}
        </ion-badge>
      </ion-toolbar>
      <slot />
    </ion-header>
    <ion-content>
      <ion-grid class="ion-no-padding">
        <gro-spinner v-if="loading" />
        <component v-else-if="!error"
                   :is="graphData.type"
                   :dataRows="dataRows"
                   :graphMeta="graphMeta"
                   :graphData="graphData"
                   @download="downloadData" />
        <ion-col class="ion-text-center ion-padding-top" v-else-if="error === 'not-found'">
          <p>{{ 'The data can not be found or the link is no longer valid.' }}</p>
        </ion-col>
        <ion-col class="ion-text-center ion-padding-top" v-else>
          <p>{{ 'Something went wrong loading data' }}</p>.
        </ion-col>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import { graphApi } from '~gro-modules/Graph'
import typeComponents from './types'
import moment from 'moment'

export default {
  components: { ...typeComponents },
  props: {
    slug: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    loading: true,
    graphData: {},
    dataRows: [{}],
    graphMeta: null,
    error: null,
  }),
  methods: {
    async loadGraphData () {
      this.loading = true
      try {
        this.graphData = await graphApi.prepareDownload(this.slug)
        const { dataRows, graphMeta } = await graphApi.downloadFile(this.graphData.downloadUrl)
        this.dataRows = dataRows
        this.graphMeta = graphMeta
      } catch (e) {
        if (e.response?.status === 404) {
          this.error = 'not-found'
        } else {
          this.error = 'broken'
        }
      }
      this.loading = false
    },
    async downloadData () {
      graphApi.downloadExcel(this.slug)
    },
  },
  computed: {
    validUntil () {
      if (!this.graphData?.valid_until) return
      return moment(this.graphData.valid_until).fromNow()
    },
  },
  mounted () {
    this.loadGraphData()
  },
}
</script>
<style lang="scss">
.legend-sensor-toggle {
  width: auto;
}

.zone-toggles {
  min-height: 48px;
  margin-top: 16px;
}

.chart-container {
  height: 600px;
}

</style>
