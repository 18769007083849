import { createRouter, createWebHistory } from '@ionic/vue-router'
import { MiddlewareHelper } from '~gro-helpers'

import { BridgeMiddleware } from '~gro-modules/Bridge'
import { Storage } from '~gro-plugins'

import dashboard from './pages/dashboard/routes'
import login from './pages/login/routes'
import graph from './pages/graph/routes'
import conditions from './pages/conditions/routes'

export const loginRoute = process.env.VUE_APP_CLOUD_VIEWER === 'true' ? { name: 'login-cloud' } : { name: 'login' }
new BridgeMiddleware(loginRoute)

const base = process.env.VUE_APP_CLOUD_VIEWER === 'true' ? '/' : process.env.BASE_URL

const router = createRouter({
  history: createWebHistory(base),
  routes: [
    ...conditions,
    ...dashboard,
    ...login,
    ...graph,
    {
      path: '/reset',
      beforeEnter: (to, from, next) => {
        Storage.flush()
        next({ name: 'dashboard' })
      },
    },
  ],
})

MiddlewareHelper.initialize(router)

export default router
