import ShowPage from './ShowPage'

export default [
  {
    path: '/graph/:slug',
    name: 'graph.show',
    component: ShowPage,
    props: true,
    meta: {
      selectedBridge: false,
    },
  },
]
