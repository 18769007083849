<template>
  <ion-page>
    <ion-content class="ion-padding">
      <ion-grid class="full-height">
        <ion-row class="ion-align-items-center full-height ion-justify-content-center">
          <ion-col size="2" class="ion-hide-md-down">
            <a href="https://apps.apple.com/nl/app/e-gro-companion/id1448697656" target="_blank">
              <img :src="$asset('/images/apple-store-badge.png')" class="store-badge" />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.rockwool.grodan.companion&hl=en&gl=US"
               target="_blank">
              <img :src="$asset('/images/play-store-badge.png')" class="store-badge" />
            </a>
          </ion-col>
          <ion-col size="6">
            <h3>{{ $t('auth.login.Steps header') }}</h3>
            <h5>{{ $t('auth.login.Step 1') }}</h5>
            <h5>{{ $t('auth.login.Step 2') }}</h5>
            <h5>{{ $t('auth.login.Step 3') }}</h5>
          </ion-col>
          <ion-col size="3">
            <div class="qr-container">
              <template v-if="failed || processing">
                <div class="qr-blocked"></div>
                <div :style="{ backgroundImage: 'url(' + imageData + ')' }" class="qr">
                  <ion-button v-if="failed" @click="generateQR" size="large" expand="full">
                    {{ $t('auth.login.Retry') }}
                  </ion-button>
                  <ion-button v-else-if="processing" size="large" expand="full">
                    <template v-if="$bridge">
                      {{ $t('auth.login.Connecting to bridge', { bridge: $bridge.name }) }}
                    </template>
                    <template v-else>
                      {{ $t('auth.login.Connecting') }}
                    </template>
                  </ion-button>
                </div>
              </template>
              <div v-else :style="{ backgroundImage: 'url(' + imageData + ')' }" class="qr">
              </div>
            </div>
          </ion-col>
          <ion-col size="1"></ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import QRCodeLib from 'qrcode'
import Encryption from '~gro-modules/Auth/encryption'
import { authApi } from '~gro-modules/Auth'
import { Gate, GrosensComponents, Translator } from '~gro-plugins'
import { BridgeRepository, BridgeScanner, connectionType } from '~gro-modules/Bridge'
import Host from '~gro-modules/Bridge/Host'

const MAX_ATTEMPTS = 150

export default {
  data () {
    return {
      imageData: null,
      failed: false,
      processing: false,
      bridge: false,
      bridges: BridgeRepository.all,
      pairCode: '',
      attempt: 0,
    }
  },
  setup () {
    if (process.env.NODE_ENV === 'development') {
      BridgeScanner.addHost(new Host('localhost', connectionType.LOCAL).withPort(8080))
    }
    BridgeScanner.addHost(new Host(location.origin, connectionType.LOCAL).withPort(8080))
    BridgeScanner.scan()
  },
  methods: {
    async checkForBridge () {
      if (this.bridge) return
      if (BridgeRepository.all.length) {
        this.bridge = BridgeRepository.all[0]
        return this.generateQR()
      }
      await this.$wait(5)
      return this.checkForBridge()
    },
    async generateQR () {
      await BridgeScanner.stop()
      await BridgeRepository.setCurrent(this.bridge)

      this.failed = false
      this.processing = false
      this.attempt = 0
      try {
        this.pairCode = Encryption.generatePairingCode(64)
        const data = Encryption.encodePairingCode(this.pairCode)
        window.$log(`%cPair code: %c${this.pairCode}`, 'font-weight:bold', '')
        this.imageData = await QRCodeLib.toDataURL(data, {
          width: Math.max(500, window.innerWidth),
        })
      } catch (e) {
        console.error(e)
        this.failed = true
      }
      if (!this.failed) await this.pollForCredentials(this.pairCode)
    },
    async pollForCredentials (pairCode) {
      await this.$wait(2)
      try {
        const credentials = await authApi.validatePairCode(this.pairCode)
        if (credentials) {
          this.processing = true
          return this.attemptLogin(credentials.uuid, credentials.password)
        }
      } catch (e) {
        console.error(e)
        this.failed = true
      }
      if (pairCode === this.pairCode) {
        if (this.attempt < MAX_ATTEMPTS) {
          this.attempt++
          return this.pollForCredentials(pairCode)
        } else {
          this.failed = true
        }
      }
    },
    async attemptLogin (uuid, password) {
      if (await this.$bridge.login(password, uuid)) {
        await Translator.reloadLanguage()
        await GrosensComponents.loadGrowerType(true)
        await Gate.resetUser()
        this.$goto('dashboard')
        return
      }
      this.failed = true
    },
  },
  mounted () {
    this.checkForBridge()
  },
  async beforeRouteEnter (to, from, next) {
    if (await BridgeRepository.getCurrent()) return next({ name: 'dashboard' })
    if (process.env.VUE_APP_CLOUD_VIEWER === 'true') return next({ name: 'login-cloud' })
    next()
  },
}
</script>

<style lang="scss" scoped>
.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%
}

.loader-item {
  max-width: 50%;
}

.qr-container {
  height: 400px;

  ion-button {
    position: relative;
    top: 45%;
  }
}

.qr-failed {
  height: 400px;
  width: 100%;
  position: absolute;
  background-color: rgba(249, 249, 251, 0.7);
}

.qr {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
}

.store-badge {
  height: 40px;
}

</style>
