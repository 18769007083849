import LoginPage from './LoginPage'
import CloudLoginPage from './CloudLoginPage'

export default [
  {
    path: '/login',
    name: 'login',
    component: LoginPage,
    meta: {
      selectedBridge: false,
    },
  },
  {
    path: '/cloud/login',
    name: 'login-cloud',
    component: CloudLoginPage,
    meta: {
      selectedBridge: false,
    },
  },
]
