<template>
  <div class="chart-container">
    <HandheldChart v-if="!preparing"
                   :settings="settings"
                   :height="600"
                   timezone="UTC"
                   :windows="graphWindows"
                   :blocks="splitData.blocks"
                   ref="chart" />
  </div>
  <DataCards :settings="settings"
             :hover="hover"
             :loading="preparing">
    <ion-card v-if="graphMeta" class="ion-padding-bottom data-card">
      <ion-grid>
        <ion-row class="header">
          <ion-col>
            <ion-badge>{{ graphMeta.name }}</ion-badge>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>{{ $t('device.Serial number') }}</ion-col>
          <ion-col class="ion-text-end">{{ graphMeta.serialNumber }}</ion-col>
        </ion-row>
        <ion-row>
          <ion-col>{{ $t('handheld.Blocks') }}</ion-col>
          <ion-col class="ion-text-end">{{ graphMeta.blocks }}</ion-col>
        </ion-row>
        <ion-row>
          <ion-col>{{ $t('handheld.Measurements') }}</ion-col>
          <ion-col class="ion-text-end">{{ graphMeta.measurements }}</ion-col>
        </ion-row>
        <ion-row v-if="graphMeta.startedAt">
          <ion-col>{{ $t('handheld.Started at') }}</ion-col>
          <ion-col class="ion-text-end">{{ graphMeta.startedAt }}</ion-col>
        </ion-row>
        <ion-row v-if="graphMeta.stoppedAt">
          <ion-col>{{ $t('handheld.Stopped at') }}</ion-col>
          <ion-col class="ion-text-end">{{ graphMeta.stoppedAt }}</ion-col>
        </ion-row>
      </ion-grid>
    </ion-card>
  </DataCards>
  <ion-toolbar class="zone-toggles">
    <ZoneToggles v-model:sensorTypes="settings.sensorTypes" />
    <gro-button slot="end" @click="$emit('download')">{{ $t('handheld.Download') }}</gro-button>
  </ion-toolbar>
</template>

<script>
import { SensorTypeHelper } from '~gro-modules/Sensor'
import moment from 'moment'
import DataCards from '~gro-components/DataCards'
import ZoneToggles from '~gro-components/ZoneToggles'
import HandheldChart from './charts/HandheldChart'

const possibleUnits = [
  'days',
  'hours',
  'minutes',
]
export default {
  components: { HandheldChart, DataCards, ZoneToggles },
  emits: ['download'],
  props: {
    dataRows: {
      type: Array,
      required: true,
    },
    graphMeta: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    hover: {},
    preparing: true,
    graphWindows: [],
    visibleMeta: [
      { key: '', label: 'Serial number' },
    ],
    settings: {
      group: { uuid: null, visible: false },
      sensorTypes: [],
      range: {},
    },
  }),
  computed: {
    sensorTypes () {
      return SensorTypeHelper.filterChartRenderableTypes(Object.keys(this.dataRows[0]), true)
    },
    splitData () {
      let currentBlock = -1
      const timestamps = []
      const blocks = []
      const values = this.sensorTypes.reduce((object, type) => ({
        ...object,
        [type]: [],
      }), {})

      this.dataRows.forEach(entry => {
        if (!entry.time) return
        const timestamp = moment.unix(entry.time)
        if (currentBlock !== entry.block) {
          blocks.push(timestamp)
          currentBlock = entry.block
        }
        timestamps.push(timestamp.unix())
        this.sensorTypes.forEach(type => {
          const value = entry[type] ? parseFloat(entry[type]) : null
          values[type].push(value)
        })
      })
      return { timestamps, values, blocks }
    },
  },
  methods: {
    async prepareData () {
      const start = this.splitData.timestamps[0]
      const end = this.splitData.timestamps[this.splitData.timestamps.length - 1]
      const range = moment.duration(moment.unix(end).diff(moment.unix(start)))
      if (!range.isValid()) return
      const unit = possibleUnits.find((unit, index) => {
        return range[unit]() > 0 || index === possibleUnits.length - 1
      })

      this.settings.range = {
        interval: Math.ceil(range.as(unit)),
        unit,
        start,
        end,
      }
      this.settings.sensorTypes = this.sensorTypes.map(type => ({
        name: type,
        zone: SensorTypeHelper.getZoneForType(type),
        value: null,
        enabled: true,
        available: true,
      }))
      this.settings.comparedGroups = [{ serialNumber: 'Handheld', type: 'device' }]

      this.graphWindows = this.sensorTypes.map(type => ({
        device: { serialNumber: 'Handheld' },
        type,
        values: this.splitData.values[type],
        timestamps: this.splitData.timestamps,
      }))
      this.preparing = false
    },
  },
  mounted () {
    this.prepareData()
  },
}
</script>
<style lang="scss">
.legend-sensor-toggle {
  width: auto;
}

.zone-toggles {
  min-height: 48px;
}

.chart-container {
  height: 600px;
}

</style>
