<template>
  <Chart :options="chartOptions"
         :series="series"
         :axis="yAxis"
         ref="chart" />
</template>

<script>
import moment from 'moment-timezone'
import Chart from '~gro-components/Chart'
import { ref, reactive } from 'vue'
import { GraphComposer } from '~gro-modules/Graph'
import handheldChartOptions from './chartOptions'

export default {
  emits: ['update:hover'],
  components: {
    Chart,
  },
  props: {
    settings: {
      type: Object,
      required: true,
    },
    timezone: {
      type: String,
      required: true,
    },
    hover: {
      type: Object,
      required: true,
    },
    windows: {
      type: Array,
      required: true,
    },
    blocks: {
      type: Array,
      default: () => ([]),
    },
  },
  setup (props, { emit }) {
    const graphComposer = new GraphComposer('handheld')
    const chart = ref()
    const seriesRange = reactive({
      from: moment.unix(props.settings.range.start),
      to: moment.unix(props.settings.range.end),
    })

    const chartOptions = function (options) {
      const plotBands = []
      const plotLines = []
      let toDate = null
      props.blocks.forEach((block, index) => {
        if (index !== props.blocks.length - 1) {
          toDate = props.blocks[index + 1]
        } else {
          toDate = seriesRange.to
        }

        plotLines.push({
          value: block.valueOf(),
          label: {
            text: `Block ${index + 1}`,
            style: {
              transform: 'translate(-10px, -20px)',
            },
          },
        })

        if (index % 2 === 0) {
          plotBands.push({
            color: '#F0F0F0',
            from: block.valueOf(),
            to: toDate.valueOf(),
          })
        }
      })

      if (!options.xAxis) {
        options.xAxis = {}
      }
      options.xAxis.plotBands = plotBands
      options.xAxis.plotLines = plotLines

      return handheldChartOptions(options)
    }

    return graphComposer.setup(props, emit, chart, {
      chartOptions,
      windows: [props.windows],
      seriesRange,
      chart,
    })
  },
  methods: {
    downloadCsv (fileName) {
      this.$refs.chart.downloadCsv(fileName)
    },
  },
}
</script>
