<template>
  <ion-page>
    <ion-content class="ion-padding">
      <ion-grid class="full-height">
        <ion-row class="ion-align-items-center full-height ion-justify-content-center">
          <ion-col size="2" class="ion-hide-md-down">
            <a href="https://apps.apple.com/nl/app/e-gro-companion/id1448697656" target="_blank">
              <img :src="$asset('/images/apple-store-badge.png')" class="store-badge" />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.rockwool.grodan.companion&hl=en&gl=US"
               target="_blank">
              <img :src="$asset('/images/play-store-badge.png')" class="store-badge" />
            </a>
          </ion-col>
          <ion-col size="6">
            <h3>{{ $t('auth.login.Steps header') }}</h3>
            <h5>{{ $t('auth.login.Step 1') }}</h5>
            <h5>{{ $t('auth.login.Step 2') }}</h5>
            <h5>{{ $t('auth.login.Step 3') }}</h5>
          </ion-col>
          <ion-col size="3">
            <div class="qr-container">
              <template v-if="failed || processing">
                <div class="qr-blocked"></div>
                <div :style="{ backgroundImage: 'url(' + imageData + ')' }" class="qr">
                  <ion-button v-if="failed" @click="generateQR" size="large" expand="full">
                    {{ $t('auth.login.Retry') }}
                  </ion-button>
                  <ion-button v-else-if="processing" size="large" expand="full">
                    <template v-if="$bridge">
                      {{ $t('auth.login.Connecting to bridge', { bridge: $bridge.name }) }}
                    </template>
                    <template v-else>
                      {{ $t('auth.login.Connecting') }}
                    </template>
                  </ion-button>
                </div>
              </template>
              <div v-else :style="{ backgroundImage: 'url(' + imageData + ')' }" class="qr">
              </div>
            </div>
          </ion-col>
          <ion-col size="1"></ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import QRCodeLib from 'qrcode'
import Encryption from '~gro-modules/Auth/encryption'
import { authApi } from '~gro-modules/Auth'
import { Translator, GrosensComponents, Gate } from '~gro-plugins'
import { BridgeRepository, BridgeScanner, connectionType as connectionTypes } from '~gro-modules/Bridge'
import Host from '~gro-modules/Bridge/Host'

const MAX_ATTEMPTS = 150

export default {
  data () {
    return {
      imageData: null,
      bridgeData: {},
      failed: false,
      processing: false,
      bridge: false,
      bridges: BridgeRepository.all,
      pairCode: '',
      attempt: 0,
    }
  },
  methods: {
    async generateQR () {
      await BridgeRepository.clear()
      this.processing = false
      this.failed = false
      this.attempt = 0
      try {
        this.pairCode = Encryption.generatePairingCode(64)
        const data = Encryption.encodePairingCode(this.pairCode)
        this.imageData = await QRCodeLib.toDataURL(data, {
          width: Math.max(500, window.innerWidth),
        })
      } catch (e) {
        console.error(e)
        this.failed = true
      }
      if (!this.failed) await this.pollForBridge(this.pairCode)
    },
    async pollForBridge (pairCode) {
      await this.$wait(2)
      try {
        this.bridgeData = await authApi.validateCloudPairCode(this.pairCode)
      } catch (e) {
        console.error(e)
        this.failed = true
      }
      if (this.bridgeData) {
        this.processing = true
        return this.setBridge()
      }
      if (pairCode === this.pairCode) {
        if (this.attempt < MAX_ATTEMPTS) {
          this.attempt++
          return this.pollForBridge(pairCode)
        } else {
          this.failed = true
        }
      }
    },
    async setBridge () {
      let connectionType = connectionTypes.VPN
      if (this.bridgeData.bridge_type === 'virtual') {
        connectionType = connectionTypes.CLOUD
      }
      BridgeScanner.addHost(new Host(this.bridgeData.endpoint, connectionType))
      BridgeScanner.scan()
      this.checkForBridge()
    },
    async checkForBridge () {
      if (BridgeRepository.all.length) {
        await BridgeScanner.stop()
        await BridgeRepository.setCurrent(BridgeRepository.all[0])
        await this.$bridge.persist()
        return this.attemptLogin()
      }
      await this.$wait(5)
      return this.checkForBridge()
    },
    async attemptLogin () {
      if (await this.$bridge.login(this.bridgeData.password, this.bridgeData.uuid)) {
        await Translator.reloadLanguage()
        await GrosensComponents.loadGrowerType(true)
        await Gate.resetUser()
        this.$goto('dashboard')
        return
      }
      this.failed = true
    },
  },
  mounted () {
    this.generateQR()
  },
  async beforeRouteEnter (to, from, next) {
    if (await BridgeRepository.getCurrent()) return next({ name: 'dashboard' })
    if (process.env.VUE_APP_CLOUD_VIEWER !== 'true') return next({ name: 'login' })
    next()
  },
}
</script>

<style lang="scss" scoped>
.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%
}

.loader-item {
  max-width: 50%;
}

.qr-container {
  height: 400px;

  ion-button {
    position: relative;
    top: 45%;
  }
}

.qr-blocked {
  height: 400px;
  width: 100%;
  position: absolute;
  background-color: rgba(249, 249, 251, 0.7);
}

.qr {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
}

.store-badge {
  height: 40px;
}

</style>
