<template>
  <div class="chart-container">
    <HandheldChart v-if="!preparing"
                   :settings="settings"
                   :height="600"
                   :timezone="timezone"
                   :windows="graphWindows"
                   ref="chart" />
  </div>
  <ion-toolbar class="zone-toggles">
    <ZoneToggles v-model:sensorTypes="settings.sensorTypes" />
    <gro-button slot="end" @click="$emit('download')">{{ $t('handheld.Download') }}</gro-button>
  </ion-toolbar>
</template>

<script>
import { SensorTypeHelper } from '~gro-modules/Sensor'
import moment from 'moment-timezone'
import ZoneToggles from '~gro-components/ZoneToggles'
import HandheldChart from './charts/HandheldChart'
import GroButton from '../../../core-frontend/components/common/GroButton'

const possibleUnits = [
  'days',
  'hours',
  'minutes',
]
export default {
  components: { GroButton, ZoneToggles, HandheldChart },
  emits: ['download'],
  props: {
    dataRows: {
      type: Array,
      required: true,
    },
    graphData: {
      type: Object,
      required: true,
    },
    graphMeta: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    hover: {},
    preparing: true,
    graphWindows: [],
    timezone: moment.tz.guess(),
    settings: {
      group: { uuid: null, visible: false },
      sensorTypes: [],
      range: {},
    },
  }),
  computed: {
    sensorTypes () {
      return SensorTypeHelper.filterChartRenderableTypes(Object.keys(this.dataRows[0]), true)
    },
    splitData () {
      const timestamps = []
      const values = this.sensorTypes.reduce((object, type) => ({
        ...object,
        [type]: [],
      }), {})

      const timestamp = moment.unix(this.graphData.timestamp)
      this.dataRows.forEach(entry => {
        timestamps.push(timestamp.unix())
        this.sensorTypes.forEach(type => {
          const value = entry[type] ? parseFloat(entry[type]) : null
          values[type].push(value)
        })
        timestamp.subtract(3, 'minutes')
      })
      return { timestamps: timestamps.reverse(), values }
    },
  },
  methods: {
    async prepareData () {
      const start = this.splitData.timestamps[0]
      const end = this.splitData.timestamps[this.splitData.timestamps.length - 1]
      const range = moment.duration(moment.unix(end).diff())
      if (!range.isValid()) return
      const unit = possibleUnits.find((unit, index) => {
        return range[unit]() > 0 || index === possibleUnits.length - 1
      })

      this.settings.range = {
        interval: Math.ceil(range.as(unit)),
        unit,
        start,
        end,
      }
      this.settings.sensorTypes = this.sensorTypes.map(type => ({
        name: type,
        zone: SensorTypeHelper.getZoneForType(type),
        value: null,
        enabled: true,
        available: true,
      }))
      this.settings.comparedGroups = [{ serialNumber: 'Handheld', type: 'device' }]
      this.graphWindows = this.sensorTypes.map(type => ({
        device: { serialNumber: 'Handheld' },
        type,
        values: this.splitData.values[type],
        timestamps: this.splitData.timestamps,
      }))
      await this.$nextTick()
      this.preparing = false
    },
  },
  mounted () {
    this.prepareData()
  },
}
</script>
<style lang="scss">
.legend-sensor-toggle {
  width: auto;
}

.zone-toggles {
  min-height: 48px;
}

.chart-container {
  height: 600px;
}

</style>
