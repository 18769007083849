import { baseChartOptions } from '~gro-modules/Graph'

export default function () {
  return baseChartOptions({
    chart: {
      type: 'spline',
      panning: false,
      pinchType: 'none',
      resetZoomButton: false,
      zoomtype: 'none',
      animation: false,
      alignTicks: true,
    },
    navigator: {
      enabled: false,
    },
    tooltip: {
      outside: false,
      positioner: function (labelWidth, labelHeight, point) {
        let x = point.plotX - (labelWidth / 2)
        if (x < 5) x = 5
        if (x > (this.chart.plotWidth - (labelWidth / 2))) x = (this.chart.plotWidth - (labelWidth / 2))
        return ({ x, y: (this.chart.chartHeight / 2) - (labelHeight / 2) })
      },
    },
    scrollbar: {
      enabled: false,
    },
    series: [],
  }, ...arguments)
}
